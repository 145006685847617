import { DatePicker, DayOfWeek, IStackItemStyles, IStackProps, Label, Separator, Stack, TextField, defaultDatePickerStrings } from "@fluentui/react"
import React from "react";
import { UploadImage } from "./UploadImage";
import { RusDatePickerStrings } from "../types/smeta_tables";
export interface IIULSign {
    Work?: string
    FIO?: string
    Date?:string
    Buffer?:ArrayBuffer
    FileType?:string
  }
export const IulSigns = props => {
    const stackTokens = { childrenGap: 10, maxWidth:1000 };
    const columnProps: Partial<IStackProps> = {
      tokens: { childrenGap: 10, maxWidth:1000 },
    };
    const stackItemStyles: IStackItemStyles = {
      root: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    };
    const onFormatDate = (date?: Date): string => {
      const d= !date ? '' : `${'0'.repeat(2-date.getDate().toString().length)}${date.getDate()}.${'0'.repeat(2-(date.getMonth()+1).toString().length)}${(date.getMonth() + 1)}.${(date.getFullYear())}`;
      return d
    };
    const [values, setValues] = React.useState<IIULSign []>([{
      Date:onFormatDate(new Date())
    }]);
    const [value, setValue] = React.useState<Date|undefined>();

    
    const onParseDateFromString = React.useCallback(
      (newValue: string): Date => {
        const previousValue = value || new Date();
        const newValueParts = (newValue || '').trim().split('.');
        const day =
          newValueParts.length > 0 ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10))) : previousValue.getDate();
        const month =
          newValueParts.length > 1
            ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
            : previousValue.getMonth();
        let year = newValueParts.length > 2 ? parseInt(newValueParts[2], 10) : previousValue.getFullYear();
        if (year < 100) {
          year += previousValue.getFullYear() - (previousValue.getFullYear() % 100);
        }
        return new Date(year, month, day);
      },
      [value],
    );
    const Sings=(items)=>{
      let sings=[]
      
      for (var i = 0; i < items.length; i++) { 
        sings.push(((index)=>{
          return (
            <>
              <Separator>{(items.length===1)?"Подпись":`Подпись ${index+1}`} </Separator>
              <Stack  horizontal  wrap tokens={stackTokens}  {...columnProps}>
                
                <Stack.Item grow={4} styles={stackItemStyles}>
                    <TextField
                      id={index.toString()}     
                      label="Характер работ"
                      value={items[index].Work}
                      onChange={onChangeWork}
                    />
                  </Stack.Item>
                  <Stack.Item grow={4} styles={stackItemStyles}>
                    <TextField 
                      id={index.toString()}    
                      label="ФИО"
                      value={items[index].FIO}
                      onChange={onChangeFIO}
                    />
                  </Stack.Item>
                  <Stack.Item styles={stackItemStyles}>
                      <Label>Подпись(скан.)</Label>
                      <UploadImage
                      id={index.toString()}  
                      onUpload={((buffer, type)=>{
                        values[index].Buffer=buffer
                        values[index].FileType=type
                      if ((index===values.length-1)) values.push({Date:values[index].Date})
                        setValues(values)
                        setSingList(Sings(values))
                        const curvalues= [...values]
                        curvalues.splice(values.length-1)
                        props.onChange(curvalues)
                      })}
                      />
                  </Stack.Item>
                  
                  <Stack.Item grow={2} styles={stackItemStyles}>
                  <DatePicker
                      //componentRef={datePickerRef}
                      id={index.toString()}
                      label="Дата подписи"
                      allowTextInput
                      ariaLabel="Выберите дату"
                      firstDayOfWeek={DayOfWeek.Monday}
                      //value={ new Date(values[index].Date)}
                      value={(()=>{                 
                        //return items[index].Date?new Date(items[index].Date):new Date()
                        //return new Date()

                        return items[index].Date?new Date((()=>{
                          const curdate=items[index].Date.split(".")
                          console.log(`${(/\d{2}$/g).exec(curdate[1])[0]}.${curdate[0]}.${curdate[2]}`)
                          return `${curdate[1]}.${(/\d{2}$/g).exec(curdate[0])[0]}.${curdate[2]}`
                        })()):new Date()
                      })()
                        
                      }
                      //onSelectDate={setValue as (date?: Date) => void}
                      //onSelectDate={onChangeDate}
                      onSelectDate={(newDate): void => {
                        items[index].Date=onFormatDate(newDate)
                        setValues(items)
                        const curvalues= [...values]
                        curvalues.splice(values.length-1)
                        props.onChange(curvalues)
                      }
                      }
                      formatDate={
                        onFormatDate
                      }
                      parseDateFromString={onParseDateFromString}
                      //className={styles.control}
                      // DatePicker uses English strings by default. For localized apps, you must override this prop.
                      strings={RusDatePickerStrings}
                  />
                  </Stack.Item>
              </Stack>
           </>)
        })(i))
      }
      return sings                
  }
  const onChangeWork = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText: string): void => {
    const index=Number(_.target['id'])
    values[index].Work=newText
    if ((newText.length>0)&&(index===values.length-1)) values.push({Date:values[index].Date})
    if ((newText.length===0)
     &&(index===values.length-2)
     &&((values[index].FIO==='')||!(values[index].FIO))) values.splice(values.length-1)
    setValues(values)
    setSingList(Sings(values))
    const curvalues= [...values]
    curvalues.splice(values.length-1)
    props.onChange(curvalues)
  }  
  const onChangeFIO = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText: string): void => {
    const index=Number(_.target['id'])
    values[index].FIO=newText
    if ((newText.length>0)&&(index===values.length-1)) values.push({Date:values[index].Date})
    if ((newText.length===0)
     &&(index===values.length-2)
     &&((values[index].Work==='')||!(values[index].Work))) values.splice(values.length-1)
    setValues(values)
    setSingList(Sings(values))
    const curvalues= [...values]
    curvalues.splice(values.length-1)
    props.onChange(curvalues)
  }
  const [SingList, setSingList] = React.useState<JSX.Element[] | undefined>(Sings(values));
    

    return (
        <>
        {SingList}
        </>)
}